import React from "react";
import logo from "./logo.svg";
import "./App.css";
import styled from "@emotion/styled";
import { CTA } from "./Components/CTA";

const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  `;
const Background = styled.div`
background-color: rgb(242, 242, 242);
background-image: linear-gradient(
  135deg,
  rgba(255, 255, 255, 0.1) 25%,
  rgba(0, 0, 0, 0.1) 25%,
  rgba(0, 0, 0, 0.1) 50%,
  rgba(255, 255, 255, 0.1) 50%,
  rgba(255, 255, 255, 0.1) 75%,
  rgba(0, 0, 0, 0.1) 75%,
  rgba(0, 0, 0, 0.1) 100%
);
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
`;
function App() {
  return (
    <div className="App">
      <Background />
      <CTAWrapper>
        <CTA />
      </CTAWrapper>
    </div>
  );
}

export default App;
