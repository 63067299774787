import FloatingImage from "./Letter";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import CircularGrid from "./CircularElements";
import { Links } from "./Links";

const CTAContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
`;

const OtherContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;
const DescriptiveTextFancy = styled.div`
  display: flex;
  flex-direction: column;
  font-size: clamp(2rem, 2vw, 4rem);
  font-weight: 400;
  color: black;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  margin-top: 1rem;
  font-family: Neucha;
  //render font in a way that is more legible
  font-feature-settings: "kern", "liga", "clig", "calt";
  //drop shadow
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
`;

const textclip = keyframes`
  to {
    background-position: 200% center;
  }
`;

const MagicalText = styled.a`
  font-size: clamp(2rem, 4vw, 4rem);
  font-weight: 700;
  font-family: Neucha;
  //render font in a way that is more legible
  font-feature-settings: "kern", "liga", "clig", "calt";
  //drop shadow
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${textclip} 2s linear infinite;
  cursor: pointer;
`;

const TextBlock = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  color: black;
  font-family: Neucha;
  //render font in a way that is more legible
  font-feature-settings: "kern", "liga", "clig", "calt";
  max-width: 600px;
`;

const BuyButton = styled.a`
  margin-top: 1rem;
  font-size: clamp(1.5rem, 2vw, 2rem);
  font-weight: 400;
  color: black;
  font-family: Neucha;
  //render font in a way that is more legible
  font-feature-settings: "kern", "liga", "clig", "calt";
  background: #fff;
  border: 1px solid #000;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: #000;
    color: #fff;
  }
`;

const Buy = () => (
  <BuyButton
    href="https://app.uniswap.org/#/swap?outputCurrency=0x87bd7810031f52496a8e85612d7eb802823cdd30"
    target="_blank"
  >
    Uniswap and Save the World
  </BuyButton>
);

const TitleCardWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    grid-area: title;
    `;

export const TitleCard = () => {
    return (
        <TitleCardWrapper>
        <FloatingImage letter="A" />
        <DescriptiveTextFancy>
          <span>Pause Giant AI Experiments:</span>
          <MagicalText
            href="https://futureoflife.org/open-letter/pause-giant-ai-experiments/"
            target="_blank"
          >
            An Open Letter
          </MagicalText>
        </DescriptiveTextFancy>
        </TitleCardWrapper>
    );
};
export const CTA = () => {
  return (
    <CTAContainer>
      <CircularGrid />
      <OtherContent>
        <TextBlock>
          "OpenAI's recent statement regarding artificial general intelligence,
          states that "At some point, it may be important to get independent
          review before starting to train future systems, and for the most
          advanced efforts to agree to limit the rate of growth of compute used
          for creating new models." We agree. That point is now. Therefore, we
          call on all AI labs to immediately pause for at least 6 months the
          training of AI systems more powerful than GPT-4. This pause should be
          public and verifiable, and include all key actors. If such a pause
          cannot be enacted quickly, governments should step in and institute a
          moratorium."
        </TextBlock>
        <Buy />
        <Links />
      </OtherContent>
    </CTAContainer>
  );
};
