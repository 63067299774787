import TelegramSVG from "../assets/telegram.svg";
import styled from "@emotion/styled";

type TLink = {
  alt: string;
  url: string;
  svg: string;
};

const links: TLink[] = [
  {
    alt: "telegram",
    url: "https://t.me/PauseGiantAIExperiments",
    svg: TelegramSVG,
  },
];

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  z-index: 99;
  margin-top: 50px;
`;

const LinkHref = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer: cursor;
  width: 100px;
  height: auto;
  img {
    width: 100%;
    height: auto;
  }
`;

const Link = ({ link }: { link: TLink }) => {
  return (
    <LinkHref href={link.url} target="_blank" rel="noopener noreferrer">
      <img src={link.svg} alt={link.alt} />
    </LinkHref>
  );
};
export const Links = () => {
  return (
    <LinksContainer>
      {links.map((link: TLink, index) => (
        <Link link={link} key={index} />
      ))}
    </LinksContainer>
  );
};
