import styled from "@emotion/styled";
import { useSpring, animated } from "@react-spring/web";
import LetterImg from "../assets/letter_cartoon.png";

const Container = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin: auto;
  margin-top: 4rem;
  @media (max-width: 900px) {
    margin-top: 0rem;
  }
`;

const Letter = styled(animated.div)`
  position: absolute;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
  color: black;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
`;

const ImageContainer = styled(animated.div)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 200px;
    height: auto;
  }
`;

const FloatingImage = ({ letter }: { letter: any }) => {
  const bobbingAnimation = useSpring({
    from: { bottom: "-10px" },
    to: [{ bottom: "0px" }, { bottom: "-10px" }],
    config: {
      mass: 20,
      tension: 200,
      friction: 20,
    },
    loop: true,
  });

  return (
    <Container>
      <Letter>
        <ImageContainer style={bobbingAnimation}>
          <img src={LetterImg} alt={"letter_envelope"} />
        </ImageContainer>
      </Letter>
    </Container>
  );
};

export default FloatingImage;
